import React from 'react';
import Layout from '../../../../components/Layout';
import Seo from '../../../../components/SEO';
import SectionIntro from '../../../../components/SectionIntro';
import {useStaticQuery, graphql} from 'gatsby';
import {getImage} from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import {convertToBgImage} from 'gbimage-bridge';
import BackGlossary from '../../../../components/BackGlossary';
import BannerSimple from '../../../../components/BannerSimple';

const GlossaryPageContent = () => {
  return (
    <div className="md:px-5 max-w-[1285px]">
      <div className="mb-8 text-xl font-bold text-left md:text-2xl">Under Utilization Rate (UUR)</div>
      <p className="mb-12 text-lg text-left">
        The term "under utilization rate" refers to a metric which determines to what extent a permission is being
        utilized less than expected or anticipated. In other words, it signifies that certain software-as-a-service
        (SaaS) applications or user permissions are not being used as much as they could or should be.
      </p>

      <p className="mb-12 text-lg text-left">
        This concept is relevant in managing access to software applications and resources within an organization. If
        users have been granted access to SaaS systems or specific permissions, but are not actively utilizing them, it
        can lead to wasted resources and potential security risks, as they could become overlooked points of entry for
        unauthorized access if not properly monitored.
      </p>

      <p className="mb-12 text-lg text-left">
        Organizations strive to achieve a balance between granting users access to the resources they need to perform
        their tasks while ensuring that all assigned permissions are utilized. Regularly monitoring and managing users’
        accesses to systems and permissions can help organizations optimize their cybersecurity posture and resource
        allocation.
      </p>

      <p className="mb-6 text-lg text-left">
        To detect and respond to underutilization rates in access permissions, organizations must consider implementing
        an ITDR system like Trustle. Trustle discovers accounts that may have zero to no permissions usage, and provides
        the ability to remove this standing access manually or automatically. User impact is minimized via JIT access
        request workflow while keeping management in the loop as needed. This proactive approach can help improve
        resource management, enhance security, and potentially reduce unnecessary costs associated with unused licenses
        or permissions.
      </p>
    </div>
  );
};

const UnderUtilizationRate = () => {
  const dataImages = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: {eq: "bg/rebrand/bg-main-top.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF], quality: 100)
        }
      }
    }
  `);
  const {placeholderImage} = dataImages;
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);
  const title = `UUR`;
  const description = `Under Utilization Rate`;
  return (
    <Layout seo={<Seo title={`Glossary: ${title} | Trustle`} description={description} />}>
      <BackgroundImage
        Tag="section"
        // Spread bgImage into BackgroundImage:
        {...bgImage}
        preserveStackingContext
        className="bg-cover"
      >
        <section className="">
          <div className="flex flex-col pt-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto ">
            <SectionIntro isPageHeader hero="Glossary" title={title} description={`(${description})`} />
          </div>
        </section>

        <section className="pb-16 ">
          <div className="flex flex-col py-16 mx-5 md:mx-8 lg:px-4 lg:max-w-[1920px] lg:mx-auto">
            <div className=" max-w-[1360px] 3xl:max-w-[1420px] mx-auto text-blue">
              <div className="py-16">
                <BackGlossary />
              </div>
              <GlossaryPageContent />
            </div>
          </div>
          <BannerSimple
            title="Curious how Trustle works?"
            description={'Get an inside look at Trustle. Schedule a demo with us today.'}
          />
        </section>
      </BackgroundImage>
    </Layout>
  );
};

export default UnderUtilizationRate;
